var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state.userData.nonGeoPicklFeature == 1
        ? _c("CreatePicklPopup", {
            attrs: {
              OnBoarded: _vm.onBoarded,
              LoadingBrand: _vm.LoadingBrand,
              brandId: _vm.brandId,
              userId: _vm.userId,
              popupId: "create-pickl-popup",
              surveyFeatureAvailable: _vm.surveyFeatureAvailable,
              lockPicklFeature: _vm.lockPicklFeature
            },
            on: { UpdatePicklList: _vm.picklCreated }
          })
        : _c("h3", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              " Feature is not available for you. Please contact Pickl Team!"
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }