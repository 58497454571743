<template>
    <div>
        <CreatePicklPopup v-if="$store.state.userData.nonGeoPicklFeature == 1" :OnBoarded="onBoarded" :LoadingBrand="LoadingBrand" :brandId="brandId" :userId="userId" popupId="create-pickl-popup" @UpdatePicklList="picklCreated" :surveyFeatureAvailable="surveyFeatureAvailable" :lockPicklFeature="lockPicklFeature"/>
        <h3 v-else style="text-align:center;"> Feature is not available for you. Please contact Pickl Team!</h3>
    </div>
</template>

<script>
import CreatePicklPopup from '@/components/common/CreateNonGeoPicklPage.vue'

export default {
  data () {
    return {
      dashboardContent: {},
      busy: true,
      brandId: '',
      userId: 0,
      onBoarded: 0,
      LoadingBrand: true,
      surveyFeatureAvailable: false,
      lockPicklFeature: false
    }
  },
  name: 'CreatePickl',
  components: {
    CreatePicklPopup
  },
  methods: {
    picklCreated () {

    }
  },

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.userId = this.$store.state.userData.id
    this.onBoarded = this.$store.state.userData.onBoarded
    if (this.$store.state.userData.surveyFeature === 1) {
      this.surveyFeatureAvailable = true
    }
    if (this.$store.state.userData.lock_pickl_feature === 1) {
      this.lockPicklFeature = true
    }
    this.LoadingBrand = false
  }
}
</script>

<style scoped>
h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    margin-top: 0;
}

.emoticon {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 29px;
        line-height: 45px;
    }
}
</style>
